//7x5 = 1.4:1 = 2100px by 1500px
//6x4 = 1.5:1 = 1800px by 1200px
.image-wrap {
    position: relative;
    margin: auto;
    max-width: 200px;
    max-height: 200px;
    --image-aspect-ratio: 1;

    &:before {
        display: block;
        content: "";
        padding-bottom: calc(100% * var(--image-aspect-ratio));
    }

    &.landscape:before {
        padding-bottom: calc(100% / var(--image-aspect-ratio));
    }

    .image-box {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--ion-color-light);

        @media (prefers-color-scheme: dark) {
            background-color: #ffffff;
        }

        ion-img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
