section.contacts {
    ion-avatar {
        width: 34px;
        height: 34px;
    }

    ion-list {
        padding-top: 4px;
        background-color: transparent;
    }

    ion-item-option {
        margin-top: 1px;
        text-transform: capitalize;
    }

    ion-item-sliding {
        border-bottom: 1px solid var(--ion-border-color);
    }

    ion-item::part(native) {
        background-color: var(--ion-background-color);
        margin-bottom: -1px;
    }

    .contact-address {
        color: var(--ion-text-color);
    }

    .contact-name {
        font-family: var(--ion-font-family-bold);
        font-size: 17px;
    }

    .contact-facility {
        font-size: 14px;

        p {
            margin-bottom: 0;
        }
    }
}
