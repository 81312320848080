.no-header-page {
    padding-top: var(--ion-safe-area-top);
}

section:not(.full-width) {
    --padding-start: 20px;
    --padding-end: 20px;
    padding-inline-start: calc(var(--padding-start) + var(--ion-safe-area-left, 0px));
    padding-inline-end: var(--padding-end);

    ion-item {
        --padding-start: 0px;
        --padding-end: 0px;
    }
}

.google-button {
    --ion-color-primary: #ffffff;
    --ion-color-primary-rgb: 255, 255, 255;
    --ion-color-primary-contrast: #222222;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #e0e0e0;
    --ion-color-primary-tint: #ffffff;
    --border-color: #eaeaea;
    --border-width: 1px;
    --border-style: solid;
}

.apple-button {
    --ion-color-primary: #000000;
    --ion-color-primary-rgb: 0, 0, 0;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #000000;
    --ion-color-primary-tint: #1a1a1a;

    @media (prefers-color-scheme: dark) {
        --ion-color-primary: #ffffff;
        --ion-color-primary-rgb: 255, 255, 255;
        --ion-color-primary-contrast: #222222;
        --ion-color-primary-contrast-rgb: 0, 0, 0;
        --ion-color-primary-shade: #e0e0e0;
        --ion-color-primary-tint: #ffffff;
    }
}

.bg-alt {
    background-color: #ddd;

    @media (prefers-color-scheme: dark) {
        background-color: #222;
    }
}

.back-button {
    --icon-font-size: 18px;
    --icon-padding-start: 10px;

    ion-icon {
        color: var(--ion-color-medium);

        @media (prefers-color-scheme: dark) {
            color: #fff;
        }
    }
}

ion-label.label-stacked {
    font-family: var(--ion-font-family-bolder);
    font-size: 14px;
    letter-spacing: 1px;
}

ion-label.label-stacked {
    font-family: var(--ion-font-family-bolder) !important;
}

.md ion-label.label-stacked {
    margin-bottom: 5px;
}

ion-button.elongated {
    height: 2em;
    font-family: "Lato";
    --padding-start: 20px;
    --padding-end: 20px;
}

ion-text.toggle-password {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 12px;
    z-index: 2;
    line-height: 2;
    padding: 8px 0;
    cursor: pointer;
}

form {
    margin-bottom: 20px;

    ion-item,
    .ion-input-group {
        margin-bottom: 10px;
        --background-focused-opacity: 0;
        --inner-padding-start: 0px;
        --inner-padding-end: 0px;
        --padding-bottom: 4px;
        --ion-item-background: none;

        &.item-label-stacked ion-label {
            font-size: 14px;
        }

        &.item-label-stacked ion-input,
        &.item-label-stacked ion-select {
            --padding-top: 0 !important;
            --padding-bottom: 0 !important;
            font-size: 18px;
            line-height: 21px;
        }

        // Ionic 6 uses direct CSS properties
        &.item-label-stacked ion-datetime {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            font-size: 22px;
        }

        &.ion-touched.ion-invalid,
        &.ion-dirty.ion-invalid {
            --background: var(--ion-color-danger-tint);

            @media (prefers-color-scheme: dark) {
                --background: none;
                --border-color: var(--ion-color-danger);
                --border-width: 1px;
            }
        }

        .error-text {
            font-size: 10px;
            line-height: 1.25;
            margin-top: 4px;
        }

        .edit-btn {
            position: absolute;
            right: 0;
            bottom: var(--padding-bottom);
            z-index: 2;
            cursor: pointer;
        }
    }
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.25;
}

h2 {
    font-family: var(--ion-font-family-light);
    font-size: 28px;
    color: black;

    @media (prefers-color-scheme: dark) {
        color: white;
    }
}

h3 {
    font-family: var(--ion-font-family-bolder);
    font-size: 24px;
}

h4 {
    font-family: var(--ion-font-family-bold);
    font-size: 18px;
}

h5 {
    font-family: var(--ion-font-family-bolder);
    font-size: 14px;
}

h6 {
    font-family: var(--ion-font-family-bolder);
    font-size: 11px;
}

p,
ol li,
ul li {
    font-size: 14px;
}

.PinturaRoot {

    p,
    ol li,
    ul li {
        font-size: unset;
        line-height: unset;
    }
}

ion-modal.splash-animation {
    .image-wrapper {
        height: 100vh;
        background: #fff;

        img {
            margin: auto;
            height: 100%;
            object-fit: contain;
        }
    }
}

.modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    .modal-body {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.9);
        border-top: solid 4px var(--ion-color-primary);
        background-color: #fff;
        padding: 20px;

        @media (prefers-color-scheme: dark) {
            background-color: #000;
        }

        h3 {
            font-family: var(--ion-font-family-light);
            font-size: 28px;
        }

        p {
            font-size: 14px;
        }

        ion-thumbnail {
            --size: 117px;
            margin: auto;
        }

        ion-button {
            font-size: 14px;
            margin-top: 2em;
        }
    }
}

ion-item.item-text-wrap {
    ion-label {
        white-space: unset !important;
        overflow: unset !important;

        h4 {
            font-size: 18px;
        }

        p {
            color: var(--ion-text-color);
        }
    }
}

.section-header {
    font-size: 12px;
    font-family: var(--ion-font-family-bold);
    text-transform: uppercase;
    line-height: 1.25;
    letter-spacing: 0.8px;
    color: var(--ion-color-medium);
    overflow: hidden;

    span {
        position: relative;
        transform: translateY(-50%);

        &:before,
        &:after {
            border-top: solid 1px #cecece;
            position: absolute;
            content: "";
            top: 6px;
            width: 100%;
        }

        &:before {
            right: 100%;
            margin-right: 15px;
        }

        &:after {
            left: 100%;
            margin-left: 15px;
        }
    }
}

img.dark-mode {
    display: none;
}

body.dark {
    img.light-mode {
        display: none;
    }

    img.dark-mode {
        display: unset;
    }
}

swiper-container.welcome {
    min-height: 100%;
    text-align: center;
}

.bullet-container {
    text-align: center;

    .bullet {
        margin: 0 2px;
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 100%;
        background-color: var(--ion-pagination-color);
        opacity: 0.2;

        &.bullet-active {
            opacity: 1;
        }
    }
}

a:hover {
    text-decoration: none;
    color: var(--ion-color-primary-shade);
}

.primary-link-btn {
    color: var(--ion-color-primary);
}

.cta-primary {
    font-size: 14px;
    font-family: var(--ion-font-family-bold);
    background: var(--ion-color-primary);
    color: white;
    border-radius: 6px;
    padding: 7px 30px;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        color: var(--ion-color-medium);
    }
}

.text-color {
    color: var(--ion-text-color);
}

.text-small {
    font-size: 10px;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: 900 !important;
}

.header-pelipost-logo {
    width: 15%;
    min-width: 145px;
}

.sidebar-pelipost-logo {
    width: 50%;
}