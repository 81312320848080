ion-tab-bar.checkout {
	--ion-tab-bar-background: var(--ion-background-color);

	ion-tab-button {
		background-color: #fff;
		text-transform: uppercase;
		font-family: var(--ion-font-family-bold);
		font-size: 15px;
		line-height: 25px;
		letter-spacing: 1px;
		color: #000;

		@media (prefers-color-scheme: dark) {
			background-color: #000;
			color: #fff;
		}

		span {
			padding: 0 3px 5px 3px;
		}

		&.tab-selected {
			color: var(--ion-color-primary);

			span {
				padding-top: 2px;
				border-bottom: 2px solid var(--ion-color-primary);
			}
		}
	}
}

.discount-input {
	input.native-input.sc-ion-input-ios {
		padding-bottom: 0;
		padding-top: 0;
	}
}
