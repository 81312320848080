@import "../theme/variables.scss";
$primary-color: #ffab00;

@mixin main-colors {
    --ion-font-family: "Lato";
    --ion-font-family-light: "Lato-Light";
    --ion-font-family-bold: "Lato-Bold";
    --ion-font-family-bolder: "Lato-Black";
    --ion-border-color: #a5a5a5;
    --border-radius: 6px;
    --ion-pagination-color: #7a7a7a;

    --ion-color-primary: #ffab00;
    --ion-color-primary-rgb: 255, 171, 0;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #e09600;
    --ion-color-primary-tint: #ffb31a;

    --ion-color-dark: #222222;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e1e1e;
    --ion-color-dark-tint: #383838;

    --ion-color-medium: #858585;
    --ion-color-medium-rgb: 133, 133, 133;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #757575;
    --ion-color-medium-tint: #919191;

    --ion-color-light: #f4f6f8;
    --ion-color-light-rgb: 244, 246, 248;
    --ion-color-light-contrast: #a0a0a0;
    --ion-color-light-contrast-rgb: 160, 160, 160;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f7f9;

    --ion-color-danger: #ff4848;
    --ion-color-danger-rgb: 255, 72, 72;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e03f3f;
    --ion-color-danger-tint: #fff4f4;

    --ion-color-blackandwhite: #000000;
    --ion-color-blackandwhite-rgb: 0, 0, 0;
    --ion-color-blackandwhite-contrast: #ffffff;
    --ion-color-blackandwhite-contrast-rgb: 255, 255, 255;
    --ion-color-blackandwhite-shade: #000000;
    --ion-color-blackandwhite-tint: #1a1a1a;

    --ion-color-green: #00cf52;
    --ion-color-green-rgb: 0, 207, 82;
    --ion-color-green-contrast: #ffffff;
    --ion-color-green-contrast-rgb: 255, 255, 255;
    --ion-color-green-shade: #00b648;
    --ion-color-green-tint: #1ad463;
}

.ion-color-blackandwhite {
    --ion-color-base: var(--ion-color-blackandwhite);
    --ion-color-base-rgb: var(--ion-color-blackandwhite-rgb);
    --ion-color-contrast: var(--ion-color-blackandwhite-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blackandwhite-contrast-rgb);
    --ion-color-shade: var(--ion-color-blackandwhite-shade);
    --ion-color-tint: var(--ion-color-blackandwhite-tint);
}

.ion-color-green {
    --ion-color-base: var(--ion-color-green);
    --ion-color-base-rgb: var(--ion-color-green-rgb);
    --ion-color-contrast: var(--ion-color-green-contrast);
    --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
    --ion-color-shade: var(--ion-color-green-shade);
    --ion-color-tint: var(--ion-color-green-tint);
}

:root {
    @include main-colors;

    --ion-text-color: #222222;
    --ion-tab-bar-background: #f4f6f8;
    --ion-toolbar-background: #ffffff;
    --ion-card-background: #ffffff;
    --slider-background: #f4f6f8;
    --ion-item-background: #ffffff;
    --ion-background-color: #ffffff;
}

@media (prefers-color-scheme: dark) {
    body,
    .md body,
    .ios body {
        @include main-colors;

        --ion-text-color: #ffffff;
        --ion-pagination-color: #ffffff;
        --ion-tab-bar-background: #000000;
        --ion-toolbar-background: #000000;
        --slider-background: #222222;
        --ion-background-color: #000000;
        --ion-background-color-rgb: #000000;
        --ion-card-background: #000000;
        --ion-item-background: #000000;

        --ion-color-blackandwhite: #ffffff;
        --ion-color-blackandwhite-rgb: 255, 255, 255;
        --ion-color-blackandwhite-contrast: #000000;
        --ion-color-blackandwhite-contrast-rgb: 0, 0, 0;
        --ion-color-blackandwhite-shade: #e0e0e0;
        --ion-color-blackandwhite-tint: #ffffff;
        --ion-border-color: white;
    }

    .modal-body {
        color: var(--ion-text-color);
    }
}

ion-back-button {
    padding: 0 25px 0 10px;

    color: var(--ion-color-medium);

    &::part(icon) {
        height: 20px;
    }

    &::part(text) {
        display: none;
    }
}

ion-card {
    color: var(--ion-text-color);
    margin: 10px 10px 5px 10px;
    position: relative;
    border: 1px solid var(--ion-border-color);
    border-radius: var(--border-radius);
    box-shadow: 1px 1px 5px var(--ion-border-color);

    &:focus,
    &:active,
    &.active {
        border-color: var(--ion-color-primary);
        box-shadow: 1px 1px 5px var(--ion-color-primary);
    }

    ion-card-content {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    ion-row,
    ion-col {
        padding: 0;
    }

    .title {
        font-family: var(--ion-font-family-bold);
        font-size: 17px;
        line-height: 1.25;
    }

    .subtitle,
    .subtitle span {
        font-family: "Lato";
        font-size: 14px;
        line-height: 1.25;
    }

    &::part(native) {
        margin-bottom: -1px;
        padding-left: 0;
    }

    &:hover {
        cursor: pointer;
    }
}

ion-menu-button {
    color: black;

    @media (prefers-color-scheme: dark) {
        color: var(--ion-color-medium);
    }
}

ion-select {
    flex: 1;
    width: 100%;
    max-width: 100%;
    border-bottom: solid 1px var(--ion-border-color);
}

ion-button,
.custom-button {
    font-size: 14px;
    font-weight: bold;
    text-transform: none;
    --border-radius: 6px;
    --box-shadow: none;

    &.button-block {
        font-family: var(--ion-font-family-bold);
        font-size: 16px;
        letter-spacing: 0;
        --padding-top: 8px;
        --padding-bottom: 8px;
        --padding-left: 8px;
        --padding-right: 8px;
        height: 40px;
        text-transform: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;

        ion-text {
            margin: 0 12px;
        }
    }

    &.button-outline {
        border: 1px solid var(--ion-color-primary);
        border-radius: 6px;
    }

    &::part(native) {
        line-height: 1.25;
    }
}

.custom-button {
    cursor: pointer;
    &.button-block {
        padding-left: var(--padding-start);
        padding-right: var(--padding-end);
        padding-top: var(--padding-top);
        padding-bottom: var(--padding-bottom);
    }
}

form ion-label {
    text-transform: uppercase;
}

ion-input,
ion-datetime,
ion-searchbar {
    font-size: 22px;
    border-bottom: solid 1px var(--ion-border-color);
}

ion-input {
    font-family: var(--ion-font-family-light);
    --placeholder-color: var(--ion-color-medium);
}

ion-datetime {
    font-family: var(--ion-font-family);

    ::placeholder {
        color: var(--ion-color-medium);
    }

    @media (prefers-color-scheme: dark) {
        --background: black;
        --background-rgb: #ccc;
    }
}

ion-searchbar {
    padding: 0 !important;
    height: auto !important;
    contain: unset !important;

    .searchbar-input-container {
        --background: transparent;
    }

    .searchbar-input {
        padding: 0 28px !important;
    }

    .searchbar-search-icon {
        left: 0 !important;
    }
}

ion-tabs ion-icon {
    font-size: 24px;
}

ion-progress-bar {
    height: 5px;
    --background: var(--ion-color-light);
    --buffer-background: var(--ion-color-light);
}

.header-ios {
    ion-toolbar {
        &:first-of-type {
            padding: 10px 0;
        }

        &:last-of-type {
            --border-width: 0px;
        }
    }
}

.header-md {
    &::after {
        background-image: none;
        border-bottom: solid 1px var(--ion-border-color);
        bottom: 0;
    }
}

ion-toolbar.md ion-buttons {
    align-items: center;
    justify-content: center;
    min-width: 48px;
    min-height: 48px;
}

.sc-ion-modal-ios-h {
    --background: transparent;
}

.ion-page {
    overflow: auto;
}

ion-button[type="submit"] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

ion-button.clear {
    --ion-color-primary: rgba(255, 255, 255, 0);
    --color: #ffab00;
}
